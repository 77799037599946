import { Link } from 'gatsby';
// import PropTypes from 'prop-types';
import React from 'react';

import MainLayout from '../views/layouts/MainLayout';

const NotFoundPage = () => (
    <MainLayout>
        <Link to='/'>{/* <FormattedMessage id='demo.page2.link' /> */}</Link>
    </MainLayout>
);

NotFoundPage.propTypes = {
    // location: PropTypes.object.isRequired,
};

export default NotFoundPage;
